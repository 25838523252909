import React, {useEffect, useState, useContext} from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import route from "./routes";
import {useSelector, useDispatch} from "react-redux";
import {get_all_users, get_all_station, get_all_groups, get_all_permisions} from '../redux/users';
import {SocketContext} from "../config/ws-client";
import {TYPE_UPDATE_STATION} from '../config/ws-client'
import Station from "./admin/home/station";


const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _data_login = useSelector((state) => state.users_module.login);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [permisos, setPermissions] = useState([]);
    const {socket} = useContext(SocketContext);
    //const [token, setToken] = useState("");
    const [connect, setConnect] = useState(false);

    useEffect(() => {
        if (_data_login.status >= 200) {
            //setToken(_data_login.data.token);
            dispatch(get_all_users(_data_login.data.token));
            dispatch(get_all_station(_data_login.data.token));
            dispatch(get_all_groups(_data_login.data.token));
            dispatch(get_all_permisions(_data_login.data.token));
        } else {
            history.push("/");
        }

        if (JSON.stringify(_session_data.data) !== "{}") {
            if (_session_data.data.grupo_permiso) {
                if (_session_data.data.grupo_permiso.permisos) {
                    setPermissions(_session_data.data.grupo_permiso.permisos);
                    if (_session_data.data.grupo_permiso.permisos.filter(x => x.codigo === 'view_perfil_station').length > 0) {
                        setConnect(true);
                    }
                }
            }
        }

    }, [dispatch, _data_login, _session_data, history]);


    useEffect(() => {
        handleMessageWs();
        // eslint-disable-next-line
    }, [socket])


    const handleMessageWs = () => {
        if (socket) {
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === TYPE_UPDATE_STATION) {
                    update_station(data, socket)
                    return;
                }
            }
        }
    }


    const update_station = (data, socket) => {
        dispatch(get_all_station(_data_login.data.token));
    }

    return (
        <Router>
            <div className="header-navbar-shadow"/>
            {(!connect) ? (
                <>
                    <Navbar/>
                    <Sidebar/>
                    <Switch>
                        {/* eslint-disable-next-line*/}
                        {route.map((item, index) => {
                            if (!item.meta.requiresAuth) {
                                return (
                                    <Route path={item.path} exact={item.exact}>
                                        {<item.component {...permisos} />}
                                    </Route>
                                );
                            }

                            if (permisos.filter(x => item.meta.permission_required.includes(x.codigo)).length > 0) {
                                return (
                                    <Route path={item.path} exact={item.exact}>
                                        {<item.component {...permisos} />}
                                    </Route>
                                );
                            }
                        })}
                    </Switch>
                </>
            ) : (
                <Switch>
                    <Route path="/" exact>
                        <Station/>
                    </Route>
                </Switch>
            )}
        </Router>
    );
};
export default Index;
