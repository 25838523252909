import Users from "../pages/admin/users/index";
import CreateUser from "../pages/admin/users/create";
import UpdateUser from "../pages/admin/users/update";
import GroupList from '../pages/admin/groups/index';
import CreateGroup from '../pages/admin/groups/create';
import UpdateGroup from '../pages/admin/groups/update';
import Home from '../pages/admin/home/index';

const routes = [
    {
        path: "/",
        name: "home",
        icon: "bx bxs-user-pin",
        exact: true,
        component: Home,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Emisoras",
            permission_required: [
                "view_perfil_station_admin",
            ],
        },
    },
    {
        path: "/roles",
        name: "roles",
        icon: "bx bxs-user-pin",
        exact: true,
        component: GroupList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "view_group",
            ],
        },
    },
    {
        path: "/roles/crear",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: CreateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "add_group",
            ],
        },
    },
    {
        path: "/roles/editar/:id",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "change_group",
            ],
        },
    },
    {
        path: "/usuarios",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: Users,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "view_user",
            ],
        },
    },
    {
        path: "/usuarios/crear",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: CreateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "add_user",
            ],
        },
    },
    {
        path: "/usuarios/editar/:id",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "change_user",
            ],
        },
    }
];

export default routes;
