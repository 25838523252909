import React, {useEffect, useState} from "react";
import Footer from "../../../components/footer";
import { SocketContext } from "../../../config/ws-client";
import {useSelector} from "react-redux";
import { useContext } from "react";
import { URLAPI } from "../../../config";
//import { WEBSOCKET } from "../../../config";

const Home = () => {
    const _session_data = useSelector((state) => state.users_module._session_data);
    const {socket} = useContext(SocketContext);
    //const socket = new WebSocket(WEBSOCKET+"/ws/websocket/general/");
    const _get_all_station = useSelector(
        (state) => state.users_module._get_all_station
    );

    const [datas, setDatas] = useState([]);
    const [connect, setConnect] = useState(0);

    useEffect(() => {
        if (_get_all_station && _get_all_station.data.length > 0) {
            setDatas(_get_all_station.data);
            let cont = _get_all_station.data.filter(x => x.conectado === 'SI');
            setConnect(cont.length);
        }
    }, [_get_all_station]);


    const [user_data, setUserData] = useState({
        usuario: "",
        nombres: "",
        apellidos: "",
        foto: ""
    });

    const [msj, setMsj] = useState('');
    const [mensajes, setMensajes] = useState([]);

    useEffect(() => {
        if (_session_data && _session_data.data) {
            setUserData(_session_data.data);
        }
    }, [_session_data]);

    socket.onmessage = ((message) => {
        const dataFromServer = JSON.parse(message.data);
        if (dataFromServer) {
            setMensajes(mensajes => [...mensajes, {
                'usuario': dataFromServer.username, 
                'nombres': dataFromServer.names, 
                'mensaje': dataFromServer.message, 
                'foto': dataFromServer.photo
            }]);
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        socket.send(JSON.stringify({
            type: 'chat_message',
            username: user_data.usuario,
            names: user_data.nombres + ' ' + user_data.apellidos,
            photo: user_data.foto,
            message: msj
        }));
        setMsj('');
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <form className="card" onSubmit={handleSubmit}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-8 col-sm-6 col-12">
                                                        <section style={{backgroundColor: '#eee'}}>
                                                                <div class="row d-flex justify-content-center">
                                                                    <div class="col-11">
                                                                        <div class="card mt-1">
                                                                            <div class="card-header d-flex justify-content-between align-items-center"
                                                                                style={{borderTop: '4 solid #ffa900'}}>
                                                                                <h5 class="mb-0">Mensajes</h5>
                                                                            </div>
                                                                            <div class="card-body" style={{position: 'relative', height: 500, overflowY: 'auto'}}>
                                                                                {mensajes.map((mensaje) => (
                                                                                    (user_data.usuario === mensaje.usuario) ? (
                                                                                        <div class= "d-flex flex-row justify-content-end">
                                                                                            <p class="ms-1 mb-1 rounded-3 mr-1" style={{backgroundColor: '#d7eef7', padding: 9, fontSize: 14}}>
                                                                                                <strong className="mb-1">{mensaje.nombres}</strong><br/>
                                                                                                {mensaje.mensaje}
                                                                                            </p>
                                                                                            <img
                                                                                                src={mensaje.foto ? URLAPI + mensaje.foto : window.location.origin + "/base/app-assets/images/logo/no_perfil.png"}
                                                                                                alt="" height="38" width="38"
                                                                                                className="rounded-circle"/>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div class= "d-flex flex-row justify-content-start">
                                                                                            <img
                                                                                                src={mensaje.foto ? URLAPI + mensaje.foto : window.location.origin + "/base/app-assets/images/logo/no_perfil.png"}
                                                                                                alt="" height="38" width="38"
                                                                                                className="rounded-circle"/>
                                                                                            <p class="ms-1 mb-1 rounded-3 ml-1" style={{backgroundColor: '#f5f6f7', padding: 9, fontSize: 14}}>
                                                                                                <strong className="mb-1">{mensaje.nombres}</strong><br/>
                                                                                                {mensaje.mensaje}
                                                                                            </p>
                                                                                        </div>
                                                                                    )
                                                                                ))}
                                                                            </div>
                                                                            <div class="text-muted justify-content-start align-items-center mt-1" style={{paddingLeft: 10, paddingRight: 10}}>
                                                                                <input type="text" class="form-control mr-1" value={msj} onChange={(e) => (setMsj(e.target.value))}/>
                                                                                <div className="text-right" style={{paddingTop: 10}}>
                                                                                    <button class="btn btn-calendar" type="submit" style={{paddingTop: '0.55rem'}}>
                                                                                        Enviar
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        </section>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-12 mt-1">
                                                        <h5>Emisoras conectadas [{connect}]</h5>
                                                        {datas.map((x) => (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src={x.foto ? URLAPI + x.foto : window.location.origin + "/base/app-assets/images/logo/no_perfil.png"}
                                                                        alt="" height="38" width="38"
                                                                        className="rounded-circle"/>
                                                                    <div className="ms-5 ml-1">
                                                                        <span className="fw-bold mb-0">{x.nombres}</span><br/>
                                                                        <span className="text-muted mb-0">{x.apellidos}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="text-right">
                                                                    {x.conectado === 'SI' ?
                                                                        <div style={{
                                                                            width: 20,
                                                                            height: 20,
                                                                            borderRadius: '50%',
                                                                            background: '#48D7A4',
                                                                            display: 'flex',
                                                                            marginRight: 10
                                                                        }}/>
                                                                        :
                                                                        <div style={{
                                                                            width: 20,
                                                                            height: 20,
                                                                            borderRadius: '50%',
                                                                            background: '#ff2d55',
                                                                            display: 'flex',
                                                                            marginRight: 10
                                                                        }}/>
                                                                    }
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Home;
