import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import { useContext } from "react";
import { connectedUser, logout } from "../../../redux/users";
import Modal from "../../../components/modal";
import { URLAPI } from "../../../config";
import { SocketContext } from "../../../config/ws-client";

const Station = () => {
    const dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _session_data = useSelector((state) => state.users_module._session_data);
    const {socket} = useContext(SocketContext);

    const [user_data, setUserData] = useState({
        usuario: "",
        nombres: "",
        apellidos: "",
        url_emisora: "",
    });

    useEffect(() => {
        console.log(_session_data.data)
        if (_session_data && _session_data.data) {
            setUserData(_session_data.data);
        }
    }, [_session_data]);

    const [modal, setModal] = useState(false);

    async function disconnect_user(username, connect) {
        const response = await connectedUser(token, username, connect);
        if (response.status === 200) {
            dispatch(logout(token));
        }
    }

    const [msj, setMsj] = useState('');
    const [mensajes, setMensajes] = useState([]);

    socket.onmessage = ((message) => {
        const dataFromServer = JSON.parse(message.data);
        if (dataFromServer) {
            setMensajes(mensajes => [...mensajes, {
                'usuario': dataFromServer.username, 
                'nombres': dataFromServer.names, 
                'mensaje': dataFromServer.message, 
                'foto': dataFromServer.photo
            }]);
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        socket.send(JSON.stringify({
            type: 'chat_message',
            username: user_data.usuario,
            names: user_data.nombres + ' ' + user_data.apellidos,
            photo: user_data.foto,
            message: msj
        }));
        setMsj('');
    }

    return (
        <section>
            <div style={{position: 'absolute'}} className="mt-1 div_container">
                <div style={{position: 'absolute'}} className="div_container1">
                    <img
                        src={
                            window.location.origin + "/base/app-assets/images/logo/isotipo.png"
                        }
                        alt="branding logo"
                        className="image_isotipo"
                    />
                </div>
                <div className="div_container2 div_flex">
                    <button type="button" className="btn text-bold-700 button_disconnect" 
                        onClick={() => {
                        if (user_data.usuario) {
                            let username = user_data.usuario;
                            disconnect_user(username, 0)
                        }
                    }}>
                        Desconectar
                    </button>
                    <div className="d-flex div_margin">
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            background: '#48D7A4'
                        }}/>
                        <strong className="in-line">En linea</strong>
                    </div>
                </div>
            </div>
            <img
                src={
                    window.location.origin + "/base/app-assets/images/logo/home.png"
                }
                alt="branding logo"
                className="image_logo"
            />
            <div className="text-left">
                {user_data.url_emisora &&
                    <audio className="audio" autoPlay={true} controls="controls"> <source src={user_data.url_emisora}/> </audio> 
                }
            </div>
            <div className="text-right">
                {/*eslint-disable-next-line*/}
                <a href="#" onClick={() => {setModal(true)}} className="chat" title="Comuníquese con nosotros">
                    <div className="d-flex">
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            background: '#48D7A4'
                        }}/>
                        <strong style={{color: '#FFF', marginLeft: 10, fontSize: 16}}>Chat</strong>
                    </div>
                </a>
            </div>
            <Modal className="modal-main1" show={modal} style={{width: "30%"}}>
                <div className="modal-content1">
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className="col-9">
                                <h4 class="mb-0" style={{color: '#FFF'}}>Mensajes</h4>
                            </div>
                            <div className="col-3 text-right">
                                {/*eslint-disable-next-line*/}
                                <a style={{borderRadius: 80, background: '#000', padding: '5px 10px', color: '#FFF'}} href="#" onClick={() => {setModal(false)}}>X</a> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <form onSubmit={handleSubmit}>
                                    <div style={{position: 'relative', height: 350, overflowY: 'auto'}}>
                                        {mensajes.map((mensaje) => (
                                            (user_data.usuario === mensaje.usuario) ? (
                                                <div class= "d-flex flex-row justify-content-end">
                                                    <p class="ms-1 mb-1 rounded-3 mr-1" style={{backgroundColor: '#d7eef7', padding: 9, fontSize: 14}}>
                                                        <strong className="mb-1">{mensaje.nombres}</strong><br/>
                                                        {mensaje.mensaje}
                                                    </p>
                                                    <img
                                                        src={mensaje.foto ? URLAPI + mensaje.foto : window.location.origin + "/base/app-assets/images/logo/no_perfil.png"}
                                                        alt="" height="38" width="38"
                                                        className="rounded-circle"/>
                                                </div>
                                            ) : (
                                                <div class= "d-flex flex-row justify-content-start">
                                                    <img
                                                        src={mensaje.foto ? URLAPI + mensaje.foto : window.location.origin + "/base/app-assets/images/logo/no_perfil.png"}
                                                        alt="" height="38" width="38"
                                                        className="rounded-circle"/>
                                                    <p class="ms-1 mb-1 rounded-3 ml-1" style={{backgroundColor: '#f5f6f7', padding: 9, fontSize: 14}}>
                                                        <strong className="mb-1">{mensaje.nombres}</strong><br/>
                                                        {mensaje.mensaje}
                                                    </p>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                    <div class="text-muted justify-content-start align-items-center mt-1">
                                        <input type="text" class="form-control mr-1" value={msj} onChange={(e) => (setMsj(e.target.value))}/>
                                        <div className="text-right" style={{paddingTop: 10}}>
                                            <button class="btn btn-calendar" type="submit" style={{paddingTop: '0.55rem'}}>
                                                Enviar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </section>
    );
};

export default Station;
