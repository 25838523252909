import React, {useEffect, useState} from "react";
import {logout} from '../redux/users';
import {useDispatch, useSelector} from "react-redux";
import {URLAPI} from '../config/index'


const Navbar = (props) => {
    const dispatch = useDispatch();
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [open, setOpen] = useState(false);
    const [user_data, setUserData] = useState({
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        foto: "",
        grupo_permiso: {
            nombre: ""
        }
    });

    const [connect, setConnect] = useState(false);

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    useEffect(() => {
        if (_session_data && _session_data.data) {
            setUserData(_session_data.data);
            if (_session_data.data.grupo_permiso && _session_data.data.grupo_permiso.permisos.length > 0) {
                if (_session_data.data.grupo_permiso.permisos.filter(x => x.codigo === 'view_perfil_station').length > 0) {
                    setConnect(true);
                }
            }
        }
    }, [_session_data]);


    useEffect(() => {
        if (open) {
            openMenu();
        } else {
            hideMenu();
        }
    }, [open])


    const handleClick = () => {
        dispatch(logout(token));
    }

    const openMenu = () => {
        document.body.classList.remove('menu-hide', 'menu-collapsed')
        document.body.classList.add('menu-open')
        document.body.classList.add('menu-expanded')

        if (document.body.classList.contains('vertical-overlay-menu')) {

            document.body.style = "overflow:hidden;"
        }
    }

    const hideMenu = () => {
        document.body.classList.remove('menu-open', 'menu-expanded')
        document.body.classList.add('menu-hide')

        document.body.style = "overflow:auto;"

    }

    return (
        <>
            <div className="header-navbar-shadow"/>
            <nav className="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top ">
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        {(!connect) &&
                            <div className="navbar-collapse" id="navbar-mobile">
                                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                                    <ul className="nav navbar-nav">
                                        <li className="nav-item mobile-menu d-xl-none mr-auto"
                                            onClick={() => setOpen(!open)}
                                        >
                                            {/*eslint-disable-next-line*/}
                                            <a
                                                className="nav-link nav-menu-main menu-toggle hidden-xs"
                                                href="#"
                                                onClick={() => setOpen(!open)}
                                            >
                                                <i className="ficon bx bx-menu"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <ul className="nav navbar-nav float-right">
                                    <li className="dropdown dropdown-user nav-item">
                                        {/*eslint-disable-next-line*/}
                                        <a
                                            className="dropdown-toggle nav-link dropdown-user-link"
                                            href="#"
                                            data-toggle="dropdown"
                                        >
                                            <div className="user-nav d-sm-flex d-none">
                                                <span className="user-name">{user_data.nombres + " " + user_data.apellidos}</span>
                                                <span
                                                    className="user-status text-muted">{user_data.grupo_permiso !== undefined ? user_data.grupo_permiso.nombre : ""}</span>
                                            </div>
                                            <span>
                                                <img
                                                    className="round"
                                                    src={user_data.foto ? URLAPI + "/media/" + user_data.foto : window.location.origin + "/base/app-assets/images/icon/user.png"}
                                                    alt="avatar"
                                                    height="35"
                                                    width="35"
                                                />
                                            </span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right pb-0">

                                            <div className="dropdown-divider mb-0"/>
                                            {/*eslint-disable-next-line*/}
                                            <a className="dropdown-item" href="#" onClick={handleClick}>
                                                <i className="bx bx-power-off mr-50"/> Logout
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
