import React, {useState, useEffect} from "react";
import Index from "./pages/user-auth";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import Login from "./login";
import ResetPassword from "./pages/auth/reset-password";
import ActivateAccount from "./pages/auth/activate-account";
import RequestResetPassword from "./pages/auth/request-reset-password";
import {SocketContext, socket} from './config/ws-client';

function App() {
  const _login = useSelector((state) => state.users_module.login);
  const _session = useSelector((state) => state.users_module._session_data)
  const [login, setLogin] = useState(_login);
  const [username, setUsername] = useState("general");
  
  useEffect(() => {

    setLogin(_login);
    if (_session.data) {
      setUsername(_session.data.usuario);
    }
  }, [_login, _session]);

  return (
      <Router>
        <Switch>

          <Route exact path="/activar-cuenta/:user_id/:token/">
            <ActivateAccount/>
          </Route>
          <Route exact path="/recuperar-contrasena">
            <RequestResetPassword/>
          </Route>
          <Route exact path="/confirmar-contrasena/:uid/:token/">
            <ResetPassword/>
          </Route>
          {!login.authenticated ? (
              <>
                <Route exact path="/">
                  <Login/>
                </Route>
              </>

          ) : (
              <>
                <SocketContext.Provider value={{socket: socket(username)}}>
                  <Route path="/">
                    <Index/>
                  </Route>
                </SocketContext.Provider>
              </>
          )}

        </Switch>
      </Router>
  );
}

export default App;
