import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login, clear_user_module, LOGIN_SUCCESS} from "./redux/users";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "./components/modal"

const Login = (props) => {
    const history = useHistory();
    const _login_data = useSelector((state) => state.users_module.login);

    const [data, setData] = useState({
        username: "",
        password: "",
    });

    const [modal, setModal] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        if (_login_data.status === 200) {
            history.push("/");
        } else if (_login_data.status !== 0) {
            toast.error(_login_data.message, {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_user_module(LOGIN_SUCCESS));
        }

    }, [_login_data, history, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.username === "") {
            toast.error("Debe ingresar el usuario", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (data.password === "") {
            toast.error("Debe ingresar la contraseña", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (data.username !== "" && data.password !== "") {
            dispatch(login(data));
        }
    };

    return (
        <>
            <section>
                <div style={{position: 'absolute'}} className="mt-1 div_container">
                    <div style={{position: 'absolute'}} className="div_container1">
                        <img
                            src={
                                window.location.origin + "/base/app-assets/images/logo/isotipo.png"
                            }
                            alt="branding logo"
                            className="image_isotipo"
                        />
                    </div>
                    <div className="div_container2 div_flex">
                        <button type="button" onClick={() => {setModal(true)}} className="btn text-bold-700" style={{
                            background: '#000000',
                            fontSize: 16,
                            color: '#FFF',
                            borderRadius: 20,
                            padding: '10px 30px',
                        }}>
                            Ingresar
                        </button>
                        <div className="d-flex div_margin">
                            <div style={{
                                width: 20,
                                height: 20,
                                borderRadius: '50%',
                                background: '#ff2d55'
                            }}/>
                            <strong style={{color: '#FFF', marginLeft: 10, fontSize: 16}}>Desconectado</strong>
                        </div>
                    </div>
                </div>
                <img
                    src={
                        window.location.origin + "/base/app-assets/images/logo/home.png"
                    }
                    alt="branding logo"
                    className="image_logo"
                />
            </section>
            <Modal className="modal-main" show={modal} style={{width: "20%"}}>
                <div><ToastContainer/></div>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div class="col-12">
                            <div className="input-group has-validation">
                                <span className="input-group-text">
                                    <img
                                        src={
                                            window.location.origin + "/base/app-assets/images/icon/profile-user.png"
                                        }
                                        alt="branding logo"
                                        width={22}
                                        height={22}
                                    />
                                </span>
                                <input type="text" name="username" className="form-control" onChange={(e) => setData({...data, username: e.target.value})}/>
                            </div>
                            <div className="input-group has-validation mt-1">
                                <span className="input-group-text" id="inputGroupPrepend">
                                <img
                                    src={
                                        window.location.origin + "/base/app-assets/images/icon/bloquear.png"
                                    }
                                    alt="branding logo"
                                    width={22}
                                    height={22}
                                />
                                </span>
                                <input type="password" name="password" className="form-control" onChange={(e) => setData({...data, password: e.target.value})}/>
                            </div>
                        </div>

                    </div>
                    <div className="card-footer text-center">
                        <button type="submit" className="btn btn-calendar button_div" style={{marginBottom: 3}} onClick={() => {}}>Conectar</button>
                        <button type="button" className="btn btn-calendar button_div" onClick={() => {setModal(false)}}>Cancelar</button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default Login;
