import React from "react";
import { WEBSOCKET } from "./index";

export const socket = (username) => {
  if (username) {
    const socket = new WebSocket(
        WEBSOCKET+"/ws/websocket/emisora/"
    );
    return socket;
  }
}

export const SocketContext = React.createContext({
  socket: socket(""),
});

export const TYPE_CHAT_MESSAGE = "chat_message";
export const TYPE_UPDATE_STATION = "update_station";
