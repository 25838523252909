import React from 'react';
import './modal.css';


const Modal = ({ show= false, children, className, style, onClick }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName} onClick={onClick}>
            <div className={className} style={style}>
                <div className="modal-dialog  modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
