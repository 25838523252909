export const URLAPI = "https://pruebas.back.radio.orocash.ec";
export const WEBSOCKET = 'wss://pruebas.back.radio.orocash.ec';

export const TOKEN_PATH = "/user/token/";
export const USERS_PATH = "/user/list/";
export const STATION_PATH = "/user/station/list/";
export const USERS_UPDATE_PATH = "/user/update/";
export const USERS_CREATE_PATH = "/user/create/";
export const USER_GROUPS_PATH = "/user/group-list/";
export const USER_GET_PATH = "/user/get/";
export const USER_GET_ALL_PERMISSIONS_PATH = '/user/permission-list/';
export const USER_CREATE_GROUP_PATH = '/user/group-create/';
export const USER_UPDATE_GROUP_PATH = '/user/group-update/';
export const USER_ACTIVATE_PATH = '/user/activate/';
export const USER_CONNECTED_PATH = '/user/connected/';
export const USER_LOGOUT_PATH = '/user/logout/';
export const USER_PASSWORD_RESET_PATH = '/user/password/reset/';
export const USER_PASSWORD_RESET_CONFIRM_PATH = '/user/password/reset/confirm/';

export const APP_NAME = "Sistema de emisoras";


